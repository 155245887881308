<script>
import ConfirmModal from './ConfirmModal';
import DataTable from './DataTable';
import PageHeader from './PageHeader';

export default {
  name: 'List',
  components: {
    ConfirmModal,
    DataTable,
    PageHeader
  },
  data() {
    return {
      columns: [{ name: 'Name', code: 'name', search: true }],
      modals: {
        remove: false
      }
    };
  },
  computed: {
    actions() {
      return [
        {
          label: 'Edit',
          class: 'btn-primary',
          icon: 'pencil',
          route: `${this.$route.name.split('-')[0]}-edit`,
          params: { id: '$_id' }
        },
        {
          label: 'Delete',
          class: 'btn-danger',
          icon: 'trash',
          onClick: this.onClickDelete
        }
      ];
    }
  },
  mounted() {
    this.refreshTable();
  },
  methods: {
    onClickDelete(item) {
      this.modals.remove = item;
    },
    async onRemove() {
      const { _id, name } = this.modals.remove;

      await this.remove({ id: _id });
      this.refreshTable();
      this.$toasted.success(`Portfolio "${name}" deleted successfully`);
      this.modals.remove = false;
    },
    refreshTable() {
      this.list();
    }
  }
};
</script>
