<template>
  <div>
    <PageHeader title="Portfolio List" />
    <div class="content">
      <div class="block block-rounded">
        <div class="block-header block-header-default">
          <h3 class="block-title">All Portfolios</h3>
        </div>
        <div class="block-content">
          <DataTable :items="assetGroups" :total="total" :loading="loadingAction.list" :columns="columns" :actions="actions" :shallow-search="true">
            <template v-slot:name="slotProps">
              <router-link class="font-w600" :to="{ name: 'portfolio-dashboard', params: { id: slotProps.data._id } }">{{
                slotProps.data.name
              }}</router-link>
            </template>
          </DataTable>
        </div>
      </div>
    </div>
    <ConfirmModal
      :open="!!modals.remove"
      title="Delete Portfolio"
      :text="`Please confirm you would like to remove portfolio: <strong>${modals.remove.name}</strong><br/><br/> This <strong>will not</strong> delete the assets assigned to this portfolio. Any assigned assets will simply be unassigned.`"
      @close="
        () => {
          modals.remove = false;
        }
      "
      @submit="onRemove"
    />
  </div>
</template>
<script>
import { mapActions, mapGetters } from 'vuex';
import List from '@/components/ResourceList';

export default {
  name: 'PortfolioList',
  extends: List,
  data() {
    return {
      columns: [
        { name: 'Name', code: 'name', search: true },
        { name: 'Type', code: 'type', search: true }
      ]
    };
  },
  computed: {
    ...mapGetters({
      assetGroups: 'assetGroup/assetGroups',
      total: 'assetGroup/total',
      loadingAction: 'assetGroup/loadingAction'
    })
  },
  methods: {
    ...mapActions({
      list: 'assetGroup/list',
      remove: 'assetGroup/remove'
    })
  }
};
</script>
